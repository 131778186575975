import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useLocation } from "react-router-dom"; // Import useLocation
import ApplicationForm from "./pages/ApplicationForm/ApplicationForm";
import Return from "./AppForm/Strip/Return";
import { useDispatch } from "react-redux";
import { setUserDetails } from "./Redux/Slices/userSlice";
import MainPage from "./pages/Home/Mainpage/index";
import TermsCondition from "./pages/Home/TermsCondition/Termsandcondition";
import About from "./pages/Home/Mainpage/About";
import Login from "./pages/Login/Login";
import VerifyOtp from "./pages/VerifyOtp/VerifyOtp";
import { ToggleProvider } from "../src/ToggleContext";
import Reporting_page from "./pages/Home/Repoting_company_info";
import Each_beneficial from "./pages/Home/Each_beneficial_owner";
import Company_applicant from "./pages/Home/Company_applicant";
import TagManager from 'react-gtm-module';


function AppRoutes() {
  const location = useLocation(); // Get current location
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("session_id");

    // If `session_id` exists, redirect to `/home` without the query params
    if (sessionId) {
      // Optionally, validate the session ID here with an API call if needed

      // Redirect to /home without the query string
      navigate("/home", { replace: true });
    }
  }, [location, navigate]);
  useEffect(() => {
    // Initialize GTM with the provided GTM ID
    TagManager.initialize({ gtmId: 'GTM-MPFM6BQB' });

    // Push page view event to GTM on route change
    window.dataLayer.push({
      event: 'pageview',
      page: location.pathname,
    });
  }, [location]); // Run this effect whenever the route changes

  
  return (
    <Routes>
  
      <Route path="/login" element={<Login />} />
      <Route path="/verifyOtp" element={<VerifyOtp />} />
      <Route path="/home" element={<ApplicationForm />} />
      <Route path="/privacy_policy" element={<TermsCondition />} />
      {/* <Route path="/return" element={<Return />} /> */}
      <Route path="/" element={<MainPage />} />
      <Route path="/about_us" element={<About />} />
      <Route
        path="/reporting-company-information"
        element={<Reporting_page />}
      />
      <Route path="/each-beneficial-owner" element={<Each_beneficial />} />
      <Route path="/company-applicant" element={<Company_applicant />} />

    </Routes>
  );
}

function App() {
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");

    if (storedToken) {
      setToken(storedToken);
      fetchUserData(storedToken);
    } else {
      setToken(null);
    }
  }, [token]);

  const fetchUserData = async (token) => {
    try {
      if (token) {
        const response = await fetch(
          "https://fincensafe.com/api/user/getUser",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.ok) {
          const userData = await response.json();
          dispatch(setUserDetails({ fName: userData?.name }));
        } else {
          console.error(
            "Error fetching user data:",
            response.status,
            response.statusText
          );
        }
      } else {
        console.error("Token not found");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <ToggleProvider>
      <Router>
        <AppRoutes />
      </Router>
    </ToggleProvider>
  );
}

export default App;
