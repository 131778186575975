import React from 'react'
import Form from '../../AppForm/AppForm'
import Navbar from '../../components/Navbar/Navbar'
import { TrackingID } from '../../Config/config'
import ReactGa from 'react-ga'
import { useEffect } from 'react'
const ApplicationForm = () => {

  ReactGa.initialize(TrackingID);
  useEffect(()=>{
    ReactGa.pageview(window.location.pathname)
  } , []);

 

  
  return (
    <>
   <Form/>  
    </>
  )
}

export default ApplicationForm;
